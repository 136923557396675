<template>
  <user-auth-logs-card />
</template>
<script>
import UserAuthLogsCard from "./components/UserAuthLogsCard.vue";
export default {
  components: {
    UserAuthLogsCard,
  },
};
</script>
